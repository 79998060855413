/* eslint-disable max-lines */
// TODO: this file really needs to be broken up. perhaps by feature.
import _, { omit } from 'lodash';

import { ICONS } from 'shared/components/icon';

import sortNaturally from 'utils/sort';

import sessionModel from 'data/models/custom/session';
import uiModel from 'data/models/custom/ui';
import { store } from 'src/store';

import UserDetails from './components/user-details';
import { reportsMenuConfig } from './reports-menu-config';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

const SUPPORTED_SERVICES = {
  corelogic: ['read'],
  pricefinder: ['read'],
  xero: ['read'],
  paf_postcode_files: ['read'],
  click_to_dial: ['read'],
  docusign: ['read'],
  hellosign: ['read'],
  hmlandregistry: ['read'],
  reinzforms: ['read']
};

const serviceCustomVariables = (app, session) => {
  switch (app.service_type_id) {
    case 'paf_postcode_files':
      return {
        url: '/apps/paf',
        name: 'Custom PAF Upload'
      };
    case 'xero':
      return {
        url: '/apps/xero/settings',
        name: 'Xero'
      };
    case 'click_to_dial':
      return {
        url: `/apps/click_to_dial/#connection_id=${app.id}`,
        name: app.connection_name
      };
    case 'docusign':
      return {
        url: `/apps/docusign/#connection_id=${app.id}`,
        name: app.connection_name
      };
    case 'hellosign':
      return {
        url: `/apps/hellosign/#connection_id=${app.id}`,
        name: app.connection_name
      };
    case 'reinzforms':
      return {
        url: session?.checkUserHasPermission('reinz_integration.view_forms')
          ? '/apps/reinz/reinz_forms'
          : '/apps/reinz/custom_values',
        name: 'REINZ'
      };
  }
};

function getServiceUrl(app, session) {
  const customEntry = serviceCustomVariables(app, session);
  if (customEntry && customEntry.url) {
    return customEntry.url;
  }

  return `/apps/#connection_id=${app.id}&service_type_id=${app.service_type_id}`;
}

function getServiceName(app, session) {
  const customEntry = serviceCustomVariables(app, session);
  if (customEntry && customEntry.name) {
    return customEntry.name;
  }

  return app.connection_name;
}

function getSingleViewDefaultPage(session) {
  if (session?.checkUserHasPermission('privacy.manage'))
    return '/admin/single_view/enhanced_privacy/';
  else if (session?.checkUserHasPermission('single_view.manage'))
    return '/admin/single_view/connections/';
}

const MENU_CONFIG = (region, _officeId, session, ww) => {
  const { __DEV__ } = window;
  return [
    {
      id: 'home',
      name: 'Home',
      icon: ICONS.HOME,
      href: '/home/',
      match: '(^/home/)|(^/[^/]*$)'
    },
    {
      id: 'calendar',
      name: 'Calendar',
      icon: ICONS.CALENDAR_MENU,
      href: '/calendar/',
      match: '^/calendar/',
      accessRights: 'calendars.read_event'
    },
    {
      id: 'dashboards',
      name: 'Dashboards',
      icon: ICONS.GRAPH,
      match: '^/dashboards/',
      accessRights:
        'addon.dashboards && (dashboards.view_own || dashboards.view_all)',

      subMenu: (state) => {
        const rawDashboardItems = (state.dashboardsList || []).map(
          (dashboard) => ({
            id: `dashboard-${dashboard.id}`,
            name: dashboard.title,
            href: `/dashboards/#id=${dashboard.id}`
          })
        );

        const rawCustomDashboardItems = (state.customDashboardsList || []).map(
          (dashboard) => ({
            id: `custom-dashboard-${dashboard.id}`,
            name: dashboard.name,
            href: `/dashboards/custom/#id=${dashboard.id}`,
            accessRights: 'addon.embedded_apps'
          })
        );

        const dashboardItems = rawDashboardItems.length
          ? [
              {
                heading: true,
                icon: ICONS.GRAPH_SMALL,
                name: 'Reporting'
              },
              ...rawDashboardItems
            ]
          : [];

        const customDashboardItems = rawCustomDashboardItems.length
          ? [
              {
                heading: true,
                icon: ICONS.GRAPH_SMALL,
                name: 'Custom Dashboard',
                accessRights: 'addon.embedded_apps'
              },
              ...rawCustomDashboardItems
            ]
          : [];

        if (dashboardItems.length || customDashboardItems.length)
          return [...dashboardItems, ...customDashboardItems];

        return [
          {
            heading: true,
            icon: ICONS.GRAPH_SMALL,
            name: 'Reporting'
          },
          {
            isInfo: true,
            content: "You don't have access to any dashboards."
          }
        ];
      }
    },
    {
      isSeparator: true
    },
    {
      id: 'listings',
      name: 'Listings',
      icon: ICONS.LISTINGS,
      match: ['^/listings/', '^/projects/', '^/project_stages/'],
      accessRights: '!addon.hide_real_estate',
      subMenu: [
        {
          heading: true,
          name: 'My Listings',
          icon: ICONS.LISTINGS
        },
        {
          id: 'myCurrentListings',
          name: 'Current',
          href: '/listings/#lens=current&filters=[["listing.listing_agent_id","in",["getUserId()"]]]'
        },
        {
          heading: true,
          icon: ICONS.LISTINGS,
          name: 'All Listings'
        },
        {
          id: 'allCurrentListings',
          name: 'Current',
          href: '/listings/#lens=current'
        },
        {
          id: 'forSale',
          name: 'For Sale',
          href: '/listings/#lens=sale'
        },
        {
          id: 'forRent',
          name: 'For Rent',
          href: '/listings/#lens=rent'
        },
        {
          heading: true,
          icon: ICONS.LISTINGS,
          name: 'Archived Listings'
        },
        {
          id: 'sold',
          name: 'Sold',
          href: '/listings/#lens=sold'
        },
        {
          id: 'leased',
          name: `${region.isEU ? 'Let' : 'Leased'}`,
          href: '/listings/#lens=leased'
        },
        {
          id: 'withdrawn',
          name: 'Withdrawn',
          href: '/listings/#lens=withdrawn'
        },
        {
          heading: true,
          name: 'Sales Progression',
          icon: ICONS.CHAINS,
          countries: ['EU']
        },
        {
          id: 'myChains',
          name: 'My Chains',
          href: '/chains/#lens=me',
          countries: ['EU']
        },
        {
          id: 'teamChains',
          name: 'Team Chains',
          href: '/chains/#lens=team',
          countries: ['EU']
        },
        {
          id: 'allChains',
          name: 'All Chains',
          href: '/chains/#lens=all',
          countries: ['EU']
        },
        {
          heading: true,
          name: 'Projects',
          icon: ICONS.LISTINGS,
          accessRights: 'addon.projects'
        },
        {
          id: 'allProjects',
          name: 'All Projects',
          href: '/projects/',
          accessRights: 'addon.projects'
        },
        {
          id: 'projectStages',
          name: 'Project Stages',
          href: '/project_stages/',
          accessRights: 'addon.projects'
        }
      ]
    },
    {
      id: 'properties',
      name: 'Properties',
      icon: ICONS.PROPERTIES,
      match: '^/(?:properties|appraisals|buildings)/',
      accessRights: '!addon.hide_real_estate',
      subMenu: [
        {
          heading: true,
          icon: ICONS.PROPERTIES,
          name: 'Properties'
        },
        {
          id: ww('valuations').toLowerCase(),
          name: `My ${ww('valued')} Properties`,
          href: '/properties/#lens=my_active_appraisals'
        },
        {
          id: 'allProperties',
          name: 'All Properties',
          href: '/properties/#all=true'
        },
        {
          id: 'appraisalHeading',
          name: ww('valuations'),
          heading: true,
          icon: ICONS.APPRAISAL
        },
        {
          id: 'myAppraisals',
          name: `My ${ww('valuations')}`,
          href: '/appraisals/#lens=my'
        },
        {
          id: 'allAppraisals',
          name: `All ${ww('valuations')}`,
          href: '/appraisals/#all=true'
        },
        {
          id: 'myAppraisals',
          name: `My ${ww('valuations')} Pipeline`,
          href: '/appraisals/pipeline#lens=my'
        },
        {
          id: 'allAppraisals',
          name: `All ${ww('valuations')} Pipeline`,
          href: '/appraisals/pipeline#lens=all'
        },
        {
          id: 'buildingsHeading',
          name: 'Buildings',
          heading: true,
          icon: ICONS.BUILDING
        },
        {
          id: 'buildings',
          name: 'All Buildings',
          href: '/buildings/'
        }
      ]
    },
    {
      id: 'contacts',
      name: 'Contacts',
      icon: ICONS.CONTACTS,
      match: '^/contacts/',
      subMenu: [
        {
          heading: true,
          icon: ICONS.CONTACTS,
          name: 'Contacts'
        },
        {
          id: 'myContacts',
          name: 'My Contacts',
          href: '/contacts/#lens=my'
        },
        {
          id: 'ownedContacts',
          name: 'Contacts Owned By Me',
          href: '/contacts/#lens=assigned'
        },
        ...(_.get(session, 'has_group')
          ? [
              {
                id: 'groupContacts',
                name: "My Group's Contacts",
                href: '/contacts/#lens=group'
              }
            ]
          : []),

        {
          id: 'allContacts',
          name: 'All Contacts',
          href: '/contacts/#all=true'
        },
        {
          heading: true,
          icon: ICONS.COMPANY,
          name: 'Companies'
        },
        {
          id: 'allCompanies',
          name: 'All Companies',
          href: '/contacts/#lens=companies'
        }
      ]
    },

    {
      id: 'leads',
      name: 'Leads',
      icon: ICONS.MARKET_LEAD,
      match: '^/(market-leads)|(leads)|(campaigns)/',
      // TODO: change to check for market_leads and campaigns addons instead
      // of region check, for that we need to make sure SH accounts have those
      // addons activated first!
      show:
        (_.get(session, 'subscription_limits.add_ons.leads') !== '0' &&
          !!_.get(session, 'subscription_limits.add_ons.leads')) ||
        region.isEU ||
        (_.get(session, 'subscription_limits.add_ons.campaigns') !== '0' &&
          !!_.get(session, 'subscription_limits.add_ons.campaigns')),
      subMenu: [
        {
          heading: true,
          icon: ICONS.LEADS,
          name: 'Leads Inbox',
          accessRights: 'addon.leads'
        },
        {
          id: 'myLeads',
          name: 'My Leads',
          href: '/leads/#lens=me',
          accessRights: 'addon.leads'
        },
        {
          id: 'teamLeads',
          name: 'Team Leads',
          href: '/leads/#lens=team',
          accessRights: 'addon.leads'
        },
        {
          id: 'unassignedLeads',
          name: 'Unassigned Leads',
          href: '/leads/#lens=unassigned',
          accessRights: 'addon.leads'
        },
        {
          id: 'allLeads',
          name: 'All Leads',
          href: '/leads/#lens=all',
          accessRights: 'addon.leads'
        },
        {
          heading: true,
          icon: ICONS.MARKET_LEAD,
          name: 'Market Leads',
          countries: ['EU']
        },
        {
          id: 'myContacts',
          name: 'My Market Leads',
          href: '/market-leads/#lens=me',
          countries: ['EU']
        },
        {
          id: 'myContacts',
          name: 'Unassigned Market Leads',
          href: '/market-leads/#lens=unassigned',
          countries: ['EU']
        },
        {
          id: 'myContacts',
          name: "Others' Market Leads",
          href: '/market-leads/#lens=others',
          countries: ['EU']
        },
        {
          id: 'myContacts',
          name: 'All Market Leads',
          href: '/market-leads/#all=true',
          countries: ['EU']
        },
        {
          heading: true,
          icon: ICONS.CAMPAIGN,
          name: 'Campaigns',
          accessRights: 'addon.campaigns'
        },
        {
          id: 'myCampaigns',
          name: 'My Campaigns',
          href: '/campaigns/#lens=me',
          accessRights: 'addon.campaigns'
        },
        {
          id: 'allCampaigns',
          name: 'All Campaigns',
          href: '/campaigns/#all=true',
          accessRights: 'addon.campaigns'
        }
      ]
    },
    {
      isSeparator: true,
      mobileOnly: true,
      accessRights: 'addon.leads'
    },
    {
      id: 'financials',
      name: 'Financials',
      icon: ICONS.FINANCE,
      match: '^/(?:trust|invoices|transactions|commissions)/',
      accessRights:
        '(addon.trust_accounts && (admin_trust.access_section || listing_trust.access_section)) || listing_invoices.access_section || agent_ledgers.see_own_ledger || (!addon.hide_real_estate && listing_commissions.access_section)',
      subMenu: (state) => {
        const isXeroActive =
          state.applications.findIndex(
            (c) =>
              c.service_type_id === 'xero' && c.connection_state === 'connected'
          ) > -1;

        return [
          {
            heading: true,
            icon: ICONS.FINANCE,
            name: 'Trusts',
            accessRights:
              'addon.trust_accounts && (admin_trust.access_section || listing_trust.access_section)'
          },
          {
            id: 'trustAccounts',
            name: 'All Trust Accounts',
            href: '/trust/accounts/',
            accessRights: 'addon.trust_accounts && admin_trust.access_section'
          },
          {
            id: 'trustFundRequests',
            name: 'Trust Funds Requests',
            href: '/trust/funds_requests',
            accessRights: 'addon.trust_accounts && listing_trust.access_section'
          },
          {
            heading: true,
            icon: ICONS.INVOICE,
            name: 'Invoices',
            accessRights: 'listing_invoices.access_section'
          },
          {
            id: 'allInvoices',
            name: 'All Invoices',
            href: '/invoices/',
            accessRights: 'listing_invoices.access_section'
          },
          {
            id: 'unpaidInvoices',
            name: 'Unpaid Invoices',
            href: '/invoices/#lens=unpaid',
            accessRights: 'listing_invoices.access_section'
          },
          {
            id: 'overdueInvoices',
            name: 'Overdue Invoices',
            href: '/invoices/#lens=overdue',
            accessRights: 'listing_invoices.access_section'
          },
          {
            id: 'payments',
            name: 'Payments',
            href: '/transactions/#lens=payments',
            accessRights: 'listing_invoices.access_section'
          },
          {
            id: 'refunds',
            name: 'Refunds',
            href: '/transactions/#lens=refunds',
            accessRights: 'listing_invoices.access_section'
          },
          {
            id: 'credits',
            name: 'Credits',
            href: '/transactions/#lens=credits',
            accessRights: 'listing_invoices.access_section'
          },
          {
            id: 'allBills',
            name: 'All Bills',
            href: '/bills/#lens=all',
            accessRights: 'listing_invoices.access_section'
          },
          ...(isXeroActive
            ? [
                {
                  id: 'syncInvoices',
                  name: 'Sync Invoices',
                  href: '/apps/xero/sync_invoices',
                  accessRights: 'listing_invoices.access_section'
                }
              ]
            : []),
          {
            heading: true,
            icon: ICONS.FINANCE,
            name: 'Other',
            accessRights:
              '(!addon.hide_real_estate && listing_commissions.access_section) || addon.agent_ledgers'
          },
          {
            id: 'commissionWorksheets',
            name: 'Commission Worksheets',
            href: '/commissions/',
            accessRights:
              '!addon.hide_real_estate && listing_commissions.access_section'
          },
          {
            id: 'agentLedgers',
            name: 'Agent Ledgers',
            href: '/agent_ledgers/ledgers/',
            accessRights:
              'addon.agent_ledgers && (agent_ledgers.manage_ledgers || agent_ledgers.payruns || agent_ledgers.bulk_transactions || agent_ledgers.see_own_ledger)'
          }
        ];
      }
    },
    {
      id: 'otherLists',
      name: 'Other Lists',
      icon: ICONS.OTHER,
      match:
        // '^/(?:audits|notes|reminders|feedback|contracts|offers|holds|workflows|scheduled_workflows|appointments)/',
        '^/(?:audits|notes|reminders|feedback|contracts|offers|holds|workflows|scheduled_workflows)/',
      subMenu: [
        {
          heading: true,
          icon: ICONS.MESSAGE,
          name: 'Messages',
          envConfigs: 'ENABLE_SCHEDULED_MESSAGES'
        },
        {
          id: 'scheduledMessages',
          name: 'Scheduled Messages',
          href: '/scheduled-messages/',
          envConfigs: 'ENABLE_SCHEDULED_MESSAGES'
        },

        // TODO: Uncomment this when BE security rights are fixed
        // https://app.clubhouse.io/rexlabs/story/51930/turn-sent-messages-back-on-after-security-rights-fixed
        // {
        //   id: 'sentMessages',
        //   name: 'Sent Messages',
        //   href: '/messages-sent/#orderBy=system_sent_at',
        //   envConfigs: 'ENABLE_SCHEDULED_MESSAGES'
        // },
        {
          id: 'failedMessages',
          name: 'Failed to Send',
          href: '/messages-failed/#orderBy=system_sent_at',
          envConfigs: 'ENABLE_SCHEDULED_MESSAGES'
        },
        {
          heading: true,
          icon: ICONS.WAND,
          name: 'Workflows',
          accessRights: 'addon.workflows || addon.system_workflows'
        },
        {
          id: 'awaitingWorkflows',
          name: 'Awaiting My Input',
          href: '/workflows/#lens=awaiting',
          accessRights: 'addon.workflows || addon.system_workflows'
        },
        {
          id: 'pendingWorkflows',
          name: 'My Pending Workflows',
          href: '/workflows/#lens=pending',
          accessRights: 'addon.workflows || addon.system_workflows'
        },
        {
          id: 'completedWorkflows',
          name: 'My Completed Workflows',
          href: '/workflows/#lens=completed',
          accessRights: 'addon.workflows || addon.system_workflows'
        },
        {
          id: 'allWorkflows',
          name: 'All Workflows',
          href: '/workflows/#lens=all',
          accessRights: 'addon.workflows || addon.system_workflows'
        },
        {
          id: 'scheduled',
          name: 'Scheduled Workflows',
          href: '/scheduled_workflows',
          accessRights: 'addon.workflows || addon.system_workflows'
        },
        {
          heading: true,
          icon: ICONS.REFERRAL,
          name: 'Referrals',
          countries: ['EU']
        },
        {
          id: 'myReferrals',
          name: 'My Referrals',
          href: '/referrals/#lens=me',
          countries: ['EU']
        },
        {
          id: 'teamReferrals',
          name: "My Team's Referrals",
          href: '/referrals/#lens=team',
          countries: ['EU']
        },
        {
          id: 'allReferrals',
          name: 'All Referrals',
          href: '/referrals/#lens=all',
          countries: ['EU']
        },
        {
          heading: true,
          icon: ICONS.OTHER,
          name: 'Other Lists'
        },
        {
          id: 'auditLogs',
          name: 'Audit Logs',
          href: '/audits#orderBy=event_timestamp',
          accessRights: 'audit_log.access_section'
        },
        {
          id: 'notes',
          name: 'Notes',
          href: '/notes/'
        },
        {
          id: 'reminders',
          name: 'Reminders',
          href: '/reminders/'
        },
        {
          id: 'feedback',
          name: 'Feedback',
          href: '/feedback/'
        },
        {
          id: 'contracts',
          name: 'Contracts',
          href: '/contracts/',
          countries: ['AUS', 'NZ']
        },
        {
          id: 'offers',
          name: 'Offers',
          href: '/contracts/',
          countries: ['EU']
        },
        {
          id: 'holds',
          name: 'Holds',
          href: '/holds/',
          accessRights: 'addon.projects'
        },
        {
          id: 'tenancyApplications',
          name: 'Tenancy Applications',
          href: '/tenancy-applications/'
        },
        // {
        //   id: 'appointments',
        //   name: 'Appointments',
        //   href: '/appointments/',
        //   accessRights: 'calendars.bulk_update_event'
        // },
        {
          isInfo: true,
          content: 'Chains can now be found under the Listings menu',
          countries: ['EU']
        }
      ]
    },
    {
      id: 'deals',
      name: 'Deals',
      icon: ICONS.DEALS,
      match: '^/deals/',
      accessRights: 'addon.deals',
      subMenu: [
        {
          heading: true,
          icon: ICONS.DEALS,
          name: 'Deals'
        },
        {
          id: 'myDeals',
          name: 'My Deals',
          href: '/deals/#lens=my'
        },
        {
          id: 'allDeals',
          name: 'All Deals',
          href: '/deals/#all=true'
        },
        {
          id: 'activeDeals',
          name: 'Active Deals',
          href: '/deals/#lens=active'
        },
        {
          id: 'archivedDeals',
          name: 'Archived Deals',
          href: '/deals/#lens=closed'
        }
      ]
    },
    {
      isSeparator: true
    },
    {
      id: 'applications',
      name: 'Applications',
      icon: ICONS.APPS,
      match: '^/apps/',
      // We get the applications from the session (?), so they are not
      // available here but instead get passed through as state to this function!
      subMenu: (state) => {
        // We want to sort and filter the applications here and add the heading
        // if there are any applications available

        // Guide Note: This is responsible for the listing of available apps under "Apps" nav
        const appItems = sortNaturally(
          state.applications.filter((app) => {
            const hasReinzForms =
              hasFeatureFlags('reinz_listing_contracts') &&
              (session?.checkUserHasPermission(
                'reinz_integration.manage_custom_values'
              ) ||
                session?.checkUserHasPermission(
                  'reinz_integration.view_forms'
                ));

            const disabledServices = [];
            if (!hasReinzForms) disabledServices.push('reinzforms');

            return omit(SUPPORTED_SERVICES, disabledServices)[
              app.service_type_id
            ];
          }),
          'connection_name'
        ).map((app) => ({
          id: `app-${app.id}`,
          name: getServiceName(app, session),
          href: getServiceUrl(app, session)
        }));

        if (appItems.length) {
          return [
            {
              heading: true,
              icon: ICONS.APPS,
              name: 'Applications'
            },

            ...appItems
          ];
        }

        return [
          {
            heading: true,
            icon: ICONS.APPS,
            name: 'Applications'
          },
          {
            isInfo: true,
            content: 'You have no active applications.'
          }
        ];
      }
    },
    {
      isSeparator: true
    },
    {
      id: 'developer',
      name: 'Developer',
      show: __DEV__,
      icon: ICONS.PROGRAMMING,
      match: [],
      subMenu: [
        {
          heading: true,
          icon: ICONS.PROGRAMMING,
          name: 'Developer Menu'
        },
        {
          id: 'system-ui',
          name: 'System UI',
          href: '/system/ui'
        },
        {
          id: 'graphiql',
          name: 'GraphiQL Explorer',
          href: '/development/graphiql/'
        },
        {
          id: 'lag-radar',
          name: 'Toggle Lag Radar',
          onClick: () => store.dispatch(uiModel.actionCreators.toggleLagRadar())
        }
      ]
    },

    {
      show: __DEV__,
      isSeparator: true
    },
    {
      isEmpty: true
    },
    {
      isSeparator: true,
      isFixedDesktop: true
    },
    // reporting config pulled out to separate file ./reports-menu-config.ts
    ...reportsMenuConfig({ session }),
    {
      isSeparator: true,
      isFixedDesktop: true
    },
    {
      id: 'admin',
      name: 'Admin',
      icon: ICONS.ADMIN,
      match: [
        '^/newsletters/',
        '^/admin/integrations/',
        '^/admin/deduplication/'
      ],
      isFixedDesktop: true,
      accessRights: `
          admin_newsletters.access_section ||
          integrations.manage_integrations ||
          dedupe.mass_dedupe
        `,
      subMenu: [
        {
          heading: true,
          icon: ICONS.ADMIN,
          name: 'Admin'
        },
        {
          id: 'newsletters',
          name: 'Newsletters',
          href: '/newsletters/',
          accessRights: 'admin_newsletters.access_section'
        },
        {
          id: 'integrations',
          name: 'Integrations',
          href: '/admin/integrations/active/',
          accessRights: 'integrations.manage_integrations'
        },
        {
          id: 'deduplication',
          name: 'Record Deduplication',
          href: '/admin/deduplication/#mode=contacts',
          accessRights: 'dedupe.mass_dedupe'
        },
        {
          isInfo: true,
          content: 'Reports can now be found under the Reporting menu',
          accessRights: `reporting_center.general_reports ||
            reporting_center.activity_and_performance ||
            reporting_center.contracts_and_commission ||
            reporting_center.invoices_and_income ||
            reporting_center.trust ||
            reporting_center.custom_reports`
        }
      ]
    },
    {
      isSeparator: true,
      isFixedDesktop: true
    },
    {
      id: 'settings',
      name: 'Settings',
      icon: ICONS.SETTINGS,
      // Add workflow to matchings
      match: [
        '^/admin/agency/',
        '^/admin/tabs/',
        '^/admin/lists/',
        '^/admin/templates/',
        '^/admin/html_templates/',
        '^/admin/users/',
        '^/admin/commissions/',
        '^/admin/vpa/',
        '^/admin/portals/',
        '^/admin/publication/',
        '^/admin/auto/',
        '^/admin/settings/',
        '^/admin/custom_validation/',
        '^/admin/workflows/'
      ],
      accessRights: `
          admin_application.access_section ||
          admin_application.manage ||
          admin_custom_tabs.manage_custom_tabs ||
          admin_lists_and_tags.manage_value_lists ||
          admin_lists_and_tags.manage_system_tags ||
          admin_lists_and_tags.manage_relationship_types ||
          admin_lists_and_tags.manage_mailing_lists ||
          admin_template_brochures.access_section ||
          saved_filters.admin ||
          admin_email_templates.access_section ||
          admin_resource_management.access_section ||
          admin_user_security.access_section ||
          (admin_user_validation.manage_rules && addon.user_validation) ||
          addon.workflows && workflows.manage_workflows ||
          admin_advanced_settings.update ||
          admin_id_verification.manage ||
          (admin_campaign_templates.manage_templates && addon.campaigns) ||
          ${
            hasFeatureFlags('update_template_privileges')
              ? 'admin_template_tracks.manage_tracks || admin_template_tracks.manage_own_tracks || admin_template_merge.manage_merges || admin_template_merge.manage_own_merges'
              : 'admin_template_tracks.access_section || admin_template_merge.access_section'
          }
        `,
      isFixedDesktop: true,
      subMenu: [
        {
          heading: true,
          icon: ICONS.SETTINGS,
          name: 'Customisations',
          accessRights: `
              admin_application.access_section ||
              admin_application.manage ||
              admin_custom_tabs.manage_custom_tabs ||
              admin_lists_and_tags.manage_value_lists ||
              admin_lists_and_tags.manage_system_tags ||
              admin_lists_and_tags.manage_relationship_types ||
              admin_lists_and_tags.manage_mailing_lists ||              
              admin_template_brochures.access_section ||
              saved_filters.admin ||
              admin_email_templates.access_section ||
              admin_resource_management.access_section ||
              (admin_user_validation.manage_rules && addon.user_validation) ||
              addon.workflows && workflows.manage_workflows ||
              admin_advanced_settings.update ||
              admin_id_verification.manage ||
              admin_user_security.access_section ||
              (admin_comm_settings.structure ||
              admin_comm_settings.deductions ||
              admin_comm_settings.agent_deductions) ||
              admin_vpa.access_section ||
              (admin_portals.access_section || admin_advanced_settings.update) ||
              admin_advanced_settings.update ||
              (admin_application.access_section || admin_advanced_settings.update || (addon.leads && admin_application.access_section)) ||
              admin_sharing.access_section ||
              billing_and_subscriptions.manage_billing || ${
                hasFeatureFlags('update_template_privileges')
                  ? 'admin_template_tracks.manage_tracks || admin_template_tracks.manage_own_tracks || admin_template_merge.manage_merges || admin_template_merge.manage_own_merges'
                  : 'admin_template_tracks.access_section || admin_template_merge.access_section'
              }
            `
        },
        {
          id: 'agencyBranding',
          name: 'Agency & Branding',
          hrefs: [
            {
              href: '/admin/agency/account/',
              accessRights: 'admin_application.access_section'
            },
            {
              href: 'admin/agency/locations/',
              accessRights: 'admin_application.access_section'
            },
            {
              href: 'admin/agency/sharing/',
              accessRights: 'admin_sharing.access_section'
            },
            {
              href: 'admin/agency/owner/',
              accessRights: 'admin_application.access_section'
            },
            {
              href: 'admin/agency/billing/',
              accessRights: 'billing_and_subscriptions.manage_billing'
            }
          ],
          accessRights:
            'admin_application.access_section || admin_application.manage || admin_sharing.access_section || billing_and_subscriptions.manage_billing'
        },
        {
          id: 'customFields',
          name: 'Custom Fields & Tabs',
          href: '/admin/tabs/#module=contacts',
          accessRights: 'admin_custom_tabs.manage_custom_tabs'
        },
        {
          id: 'customValues',
          name: 'Custom Values, Tags & Mailing Lists',
          hrefs: [
            {
              href: '/admin/lists/systemvalues/',
              accessRights: 'admin_lists_and_tags.manage_value_lists'
            },
            {
              href: '/admin/lists/tags/',
              accessRights: 'admin_lists_and_tags.manage_system_tags'
            },
            {
              href: '/admin/lists/relationships/',
              accessRights: 'admin_lists_and_tags.manage_relationship_types'
            },
            {
              href: '/admin/lists/mailing/',
              accessRights: 'admin_lists_and_tags.manage_mailing_lists'
            },
            {
              href: '/admin/lists/pipelinestages/',
              accessRights: 'admin_lists_and_tags.manage_relationship_types'
            }
          ],
          accessRights:
            'admin_lists_and_tags.manage_value_lists || admin_lists_and_tags.manage_system_tags || admin_lists_and_tags.manage_relationship_types || admin_lists_and_tags.manage_mailing_lists || admin_lists_and_tags.manage_pipeline_stages'
        },
        {
          id: 'tracksMergeBrochures',
          name: 'Tracks, Merge & Brochures',
          hrefs: [
            {
              href: '/admin/templates/tracks/',
              accessRights: hasFeatureFlags('update_template_privileges')
                ? 'admin_template_tracks.manage_tracks || admin_template_tracks.manage_own_tracks'
                : 'admin_template_tracks.access_section'
            },
            {
              href: '/admin/templates/merge/#lens=email',
              accessRights: hasFeatureFlags('update_template_privileges')
                ? 'admin_template_merge.manage_merges || admin_template_merge.manage_own_merges'
                : 'admin_template_merge.access_section'
            },
            {
              href: '/admin/templates/brochures/',
              accessRights: 'admin_template_brochures.access_section'
            },
            {
              href: '/admin/templates/newsletters/',
              accessRights: hasFeatureFlags('update_template_privileges')
                ? 'admin_template_merge.manage_merges || admin_template_merge.manage_own_merges'
                : 'admin_template_merge.access_section'
            },
            {
              href: '/admin/templates/filters/',
              accessRights: 'saved_filters.admin'
            }
          ],
          accessRights: `admin_template_brochures.access_section || saved_filters.admin || ${
            hasFeatureFlags('update_template_privileges')
              ? 'admin_template_tracks.manage_tracks || admin_template_tracks.manage_own_tracks || admin_template_merge.manage_merges || admin_template_merge.manage_own_merges'
              : 'admin_template_tracks.access_section || admin_template_merge.access_section'
          }`
        },
        {
          id: 'advancedHtmlTemplates',
          name: 'Advanced HTML Templates',
          href: '/admin/html_templates/templates/#lens=newsletter',
          accessRights:
            'admin_email_templates.access_section || admin_resource_management.access_section'
        },
        {
          id: 'embeddedApps',
          name: 'Embedded Apps',
          href: '/admin/embedded_apps/',
          accessRights: 'addon.embedded_apps && admin_advanced_settings.update'
        },
        {
          id: 'customValidation',
          name: 'Custom Validation',
          href: '/admin/custom_validation/',
          paywall: 'addon.user_validation',
          // We're currently hiding the feature AND the paywall if the user doesn't have the addon.
          // when we want to show the paywall again, remove `&& addon.user_validation` from accessRights
          // clubhouse here - https://app.clubhouse.io/rexlabs/story/44730/missing-paywall-details
          accessRights:
            'admin_user_validation.manage_rules && addon.user_validation'
        },
        {
          id: 'workflows',
          name: 'Workflows',
          href: '/admin/workflows/',
          // We're currently hiding the feature AND the paywall if the user doesn't have the addon.
          // when we want to show the paywall again, remove `addon.workflows` from accessRights
          // clubhouse here - https://app.clubhouse.io/rexlabs/story/44730/missing-paywall-details
          accessRights: 'addon.workflows && workflows.manage_workflows',
          paywall: 'addon.workflows'
        },
        {
          id: 'webhooks',
          name: 'Webhooks',
          href: '/admin/webhooks/',
          accessRights: 'admin_webhooks.manage_webhooks'
        },
        {
          id: 'metricWriteRules',
          name: 'Metric Write Rules',
          href: '/admin/metric_write_rules/',
          countries: ['EU'],
          accessRights: 'admin_advanced_settings.update'
        },
        {
          id: 'idVerification',
          name: 'ID Verifications',
          href: '/admin/identity/id_types',
          accessRights:
            'addon.aml_and_id_checks && admin_id_verification.manage'
        },
        {
          heading: true,
          icon: ICONS.OTHER,
          name: 'Other',
          accessRights: `
              admin_user_security.access_section ||
              (admin_comm_settings.structure || admin_comm_settings.deductions ||admin_comm_settings.agent_deductions) ||
              admin_vpa.access_section ||
              (admin_portals.access_section || admin_advanced_settings.update) ||
              admin_advanced_settings.update ||
              (admin_application.access_section || admin_advanced_settings.update || (addon.leads && admin_application.access_section)) ||
              (admin_campaign_templates.manage_templates && addon.campaigns)`
        },
        {
          id: 'usersSecurity',
          name: 'Users & Security',
          href: '/admin/users/manage/',
          accessRights: 'admin_user_security.access_section'
        },
        {
          id: 'financeAndCommissions',
          name: 'Finance & Commissions',
          href: '/admin/finance_and_commissions/chart_of_accounts/',
          accessRights: 'admin_chart_of_accounts.manage'
        },
        {
          id: 'advertProvidersPacks',
          name: 'Advert Providers & Packs',
          href: '/admin/vpa/providers/',
          accessRights: 'admin_vpa.access_section'
        },
        {
          id: 'portalsPublicationDefaults',
          name: 'Portals & Publication Defaults',
          href: '/admin/portals/profiles',
          accessRights:
            'admin_portals.access_section || admin_advanced_settings.update'
        },
        {
          id: 'referralSettings',
          name: 'Referral Settings',
          href: '/admin/referrals/categories/',
          countries: ['EU'],
          accessRights:
            'admin_referrals.manage_referral_categories || admin_referrals.manage_referral_merge_templates || admin_referrals.view_referral_merge_template_section'
        },
        {
          id: 'matchProfilesAlerts',
          name: 'Match Profiles & Alerts',
          href: '/admin/match_profiles_alerts/match_profiles/',
          accessRights: 'admin_advanced_settings.update'
        },
        hasFeatureFlags('enhanced_privacy')
          ? {
              id: 'singleView',
              name: 'Single View',
              href: getSingleViewDefaultPage(session),
              accessRights:
                'addon.single_view && (single_view.manage || privacy.manage)'
            }
          : {},
        {
          id: 'advancedSettings',
          name: 'Advanced Settings',
          hrefs: [
            {
              href: '/admin/settings/image/',
              accessRights: 'admin_application.access_section'
            },
            {
              href: '/admin/settings/contract_conditions/',
              accessRights: 'admin_application.access_section'
            },
            {
              href: '/admin/settings/finance/',
              accessRights: 'admin_application.access_section'
            },
            {
              href: '/admin/settings/legal/',
              accessRights: 'admin_application.access_section'
            },
            {
              href: '/admin/settings/leads/',
              accessRights: 'admin_application.access_section',
              paywall: 'addon.leads'
            },
            {
              href: '/admin/settings/deals',
              accessRights: 'addon.deals && admin_application.access_section'
            },
            {
              href: '/admin/settings/campaign_templates',
              // We're currently hiding the feature AND the paywall if the user doesn't have the addon.
              // when we want to show the paywall again, remove `&& addon.campaigns` from accessRights
              // clubhouse here - https://app.clubhouse.io/rexlabs/story/44730/missing-paywall-details
              accessRights:
                'admin_campaign_templates.manage_templates && addon.campaigns'
            },
            {
              href: '/admin/settings/last_contacted',
              accessRights: 'admin_application.access_section'
            },
            {
              href: '/admin/settings/open_homes',
              accessRights: 'admin_application.access_section'
            },
            {
              href: '/admin/settings/communication_settings',
              accessRights: 'admin_application.access_section'
            },
            {
              href: '/admin/settings/aml-settings',
              accessRights:
                'addon.aml_and_id_checks && admin_application.access_section'
            },
            {
              href: '/admin/settings/appointment_types',
              accessRights: 'addon.calendar && admin_application.access_section'
            }
          ],
          accessRights:
            'admin_application.access_section || admin_advanced_settings.update || (addon.leads && admin_application.access_section) || (admin_campaign_templates.manage_templates && addon.campaigns)'
        }
      ]
    },
    {
      id: 'userProfile',
      meta: (state) => ({
        name: state.user.name
      }),
      isFixedMobile: true,
      mobileOnly: true,
      Component: UserDetails,
      props: (state) => ({
        ...state
      }),
      subMenu: [
        {
          id: 'mySettings',
          name: 'My Settings',
          icon: ICONS.SETTINGS,
          href: '/user/profile/#page=details'
        },
        {
          id: 'emailDropbox',
          name: 'Email Dropbox',
          icon: ICONS.DEALS,
          href: '/email_dropbox#filters=[["system_filing_status","unfiled"]]'
        },
        {
          id: 'announcements',
          name: 'Announcements',
          icon: ICONS.ANNOUNCEMENTS,
          href: '/announcements'
        },
        {
          isSeparator: true
        },
        {
          id: 'reload',
          name: 'Reload',
          icon: ICONS.RELOAD,
          onClick: (params) => {
            params.reloadFrame();
          }
        },
        {
          id: 'logout',
          name: 'Logout',
          icon: ICONS.LOGOUT,
          onClick: (e) => {
            e.preventDefault();
            store.dispatch(sessionModel.actionCreators.logout());
          }
        }
      ]
    }
  ].filter((item) => item.show === undefined || item.show);
};

export default MENU_CONFIG;
